@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
    margin: 0;
    border: 0;
    box-sizing: border-box;
    font-size: 18px;
}

body {
    font-family: 'IBM Plex Sans Condensed', sans-serif;
}