.project{
  transition: padding 1s ease;
}

.section__{
  position: relative;
  max-width: 90%;
  min-height: 500px;
}

.section__:not(.project-details){
  max-width: 95%;
  width: 100%;
  margin: 5rem 0;
  background-color: var(--bg-secondary);
  box-shadow: 0 0 20px rgba(var(--bg-tertiary-rgb), 0.3);
  transition: max-width 1s ease;
}

.project a:not(.repo-link){
  position: absolute;
  color: var(--font-primary);
  top: 0;
  left: 0;
  transition: all ease 0.3s;
}

.project svg{
  margin-right: 0.5rem;
  transition: all ease 0.3s;
}

.project a:not(.repo-link):hover{
  opacity: 0.5;
}

.project a:not(.repo-link):hover svg{
  transform: translateX(-5px);
}

.project h1{
  color: var(--bg-tertiary);
  font-size: clamp(2.7rem, 8vw, 4rem);
  font-weight: 700;
  margin-left: -4px;
}

.project h3{
  color: var(--font-primary);
  font-weight: 300;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin: 0;
}

.project p{
  color: var(--font-primary);
  line-height: 1.6rem;
  max-width: 1000px;
}

.project .tags{
  margin-top: 2rem;
}

.tags, .repo-link{
  margin-left: 5px;

}

.tag{
  font-size: 0.9rem;
  color: var(--font-primary);
  border: solid 2px var(--stroke-primary);
  border-radius: 2px;
  min-width: 50px;
  padding: 0.2rem 0.5rem;
  margin-right: 10px;
  box-shadow: -0.2rem -0.2rem 0 0 var(--bg-tertiary);
}

.repo-link{
  display: inline-block;
  margin-top: 1rem;
  overflow: hidden;
  transition: all 0.1s ease;
}

.repo-link svg{
  margin: 0;
  width: 30px;
  height: 30px;
  color: var(--font-tertiary);
}

.repo-link:hover{
  transform: scale(110%);
}

.repo-link:hover svg{
  color: var(--font-tertiary);
}

.project__img img{
  width: 100%;
  height: 100%;
}

@media (min-width: 1400px){
  .project{
    padding: var(--indent);
  }
}

@media (max-width: 1400px){
  .section__:not(.project-details){
    max-width: 100%;
  }
}

@media (min-width: 1200px){
  .project{
    padding-top: calc(var(--indent) * 3);
  }
}

@media (max-width: 1200px){
  .project{
    padding-top: calc(var(--indent) * 4);
  }
}
