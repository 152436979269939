@import url('https://fonts.googleapis.com/css2?family=Life+Savers:wght@400;700&display=swap');

:root {
  --indent: 4rem;
}

@media(max-width:1200px){
  :root{
    --indent: 2rem;
  } 
}

*{
  scroll-behavior: smooth;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: var(--bg-secondary);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb);
}

#cursor, #cursorCircle{
  background-color: var(--stroke-secondary);
  width: 10px;
  aspect-ratio: 1;
  position: fixed;
  border-radius: 50%;
  z-index: 100;
  pointer-events: none;
  opacity: 0;
  transition: opacity 500ms ease;
}

#cursorCircle{
  width: 40px;
  background-color: transparent;
  border: solid 1px var(--stroke-secondary);
}

body:hover > #cursor, body:hover > #cursorCircle {
  opacity: 1;
}

header{
  text-transform: uppercase;
  margin: 0 var(--indent);
  margin-top: calc(var(--indent) / 4);
  transition: all 0.5s ease;
  padding: calc(var(--indent) / 8) calc(var(--indent) / 4);
}

.fixed{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  border: solid 1px transparent;
  border-radius: 2px;
}

.scrolled{
  background-color: rgba(var(--bg-tertiary),0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: solid 0.1rem rgba(var(--bg-tertiary-rgb), 0.5);
}

.scrolled>div>div:not(.not)>a{
  color: white !important;
}

.header-wrapper{
  width: 100%;
  min-height: 45px;
}

.header-component{
  padding: 0 1rem;
}

.header-component>*{
  font-size: 0.9rem;
}

.header-component a{
  transition: all ease 0.3s;
  cursor: pointer;
}

.header-component a{
  color: var(--font-primary) !important; 
}

.header-component a:hover{
  opacity: 0.5;
}

.header-component:has(.theme-change-btn){
  padding: 0;
}

.header-component.not:first-child{
  position: absolute;
  left: 0;
}

.nav-btn{
  display: none;
}

.star{
  font-family: 'Life Savers', serif;
  text-transform: uppercase;
  text-shadow: var(--font-tertiary) 1px 0 20px;
  font-size: 1.5rem;
  line-height: 0;
  background: var(--bg-main);
  width: 50px;
  height: 50px;
  position: relative;
  outline: solid 2px var(--bg-tertiary);
  outline-offset: -2px;
}

.star:before{
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 50px;
  width: 50px;
  background: var(--bg-tertiary);
  transform: rotate(45deg);
  transform-origin: 50% 50%;
  outline: dashed 2px var(--stroke-primary);
  outline-offset: 2px;
  transition: transform 0.7s ease;
}

.star:hover:before{
  transform: scale(0.7);
}

.header-component:has(.star) a:hover{
  opacity: 1 !important;
  color: var(--font-primary);
}

.header-component a.active{
  background-color: red;
}

.theme-change-btn{
  text-align: center;
  cursor: pointer;
  border: solid 0.1rem var(--bg-tertiary);
  border-radius: 0.3rem;
  padding: 0.4rem;
  overflow: hidden;
  background-clip: border-box;
}

.theme-change-btn svg{
  width: 30px;
  height: 30px;
  color: var(--bg-tertiary);
  transition: transform 0.5s ease-out;
}

.theme-change-btn:hover{
  border-color: var(--btn-theme-hover);
}

.theme-change-btn:hover svg{
  color: var(--btn-theme-hover);
  transform: rotate(90deg) scale(5.2);
}

.moon:hover svg{
  transform: rotate(90deg) scale(5.6) translate(8px);
}

.section{
  position: relative;
  padding: calc(var(--indent) * 2) var(--indent);
  transition: padding 0.3s ease-out;
}

.section:not(:has(.intro)){
  contain: paint;
}

.section:has(.intro){
  padding-top: calc(var(--indent) * 4) ;
  min-height: 925px;
}

.section:has(.about), .section:has(.projects){
  background-color: var(--bg-secondary);
  z-index:0;
}

.space-background{
  contain: paint;
  position: absolute;
  left: 0;
  right: 0;
  z-index: -100;
}

#moon{
  --parallax-speed: 10;
}

#astronaut{
  --parallax-speed: 4;
}

/* #astronaut:hover{
  animation: translateUp 6s;
  animation-iteration-count: infinite;
} */

/* @keyframes translateUp{
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-40px);
  }
} */

#satellite{
  --parallax-speed-x: -1;
  --parallax-speed-y: 15;
}

#space, #astronaut, #moon{
  animation: parallax linear;
  animation-timeline: scroll();
}

#satellite, #rocket{
  animation: parallaxDiagonal linear;
  animation-timeline: scroll();
}

@keyframes parallax{
  to {
    transform: translateY(calc(var(--parallax-speed) * 200px));
  }
}

@keyframes parallaxHorizontal{
  to {
    transform: translateX(calc(var(--parallax-speed-x) * 200px));
  }
}

@keyframes parallaxDiagonal{
  to {
    transform: translateY(calc(var(--parallax-speed-y) * 200px)) translateX(calc(var(--parallax-speed-x) * 200px));
  }
}

@media (min-width: 1400px){
  .section:not(:has(.intro)){
    padding: calc(var(--indent) * 3);
  }

  .section:has(.intro){
    padding-left: calc(var(--indent) * 2);
  }
}

.section>div:not(.space-background){
  max-width: 600px;
  color: var(--font-primary);
}

.animate-text{
  overflow: hidden;
}

.intro{
  max-width: 700px !important;
}

.intro h3{
  color: var(--font-primary);
  font-weight: 300;
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  word-spacing: 0.2rem;
}

.intro h1{
  color: var(--bg-tertiary);
  font-size: clamp(2.7rem, 8vw, 4rem);
  font-weight: 700;
  margin-left: -4px;
}

.intro h2 {
  color: rgba(var(--bg-tertiary-rgb), 0.5) !important;
  font-size: clamp(2.5rem, 7vw, 3.5rem) !important;
  font-weight: 600 !important;
  margin-left: -2px;
}

.divider{
  margin-top: 75px;
}

.divider h3{
  margin: 0;
}

a:not(.resume-link){
  text-decoration: none;
}

.resume-btn, .contact-btn, .home-btn{
  display: inline-block;
  width: 90px;
  color: var(--font-tertiary);
  border: solid 0.1rem var(--font-tertiary);
  border-radius: 0.3rem;
  padding: 0.3rem 0.5rem 0.5rem 0.5rem;
  margin-left: 1rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: transparent;
  transition: all .35s;
}

.curtain::before, .curtain::after{
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: -100%;
  left: 0;
  background: var(--font-secondary);
  z-index: -1;
  transition: all .35s;
}

.curtain::before{
  opacity: 0.5;
}

.curtain::after{
  transition-delay: 0.2s;
}

.curtain:hover{
  color: var(--bg-main);
  font-weight: 500;
}

.curtain:hover::before, .curtain:hover::after{
  top: 0;
}

.react-icon{
  display: none;
  font-size: 1.5rem;
  transform: scale(2);
  overflow: hidden;
}

.resume-btn:active, .contact-btn:active{
  transform: scale(1.05);
}

.section-heading{
  display: inline-block;
  text-transform: uppercase;
  background-color: var(--bg-secondary);
  border: solid 1px var(--stroke-secondary);
  box-shadow: 3px -3px 0 0 var(--bg-tertiary);
  padding: 2px 7px;
  border-radius: 2px;
  font-size: 0.9rem;
  margin: 10px 0;
}

/* .section-heading::before, .section-heading::after{
  display: inline-block;
  padding: 0 3px;
  font-size: 1rem;
  content: "~";
}

.section-heading::after{
  transform: scale(-1, 1);
} */

.section-title{
  color: var(--bg-tertiary);
  font-size: clamp(1.6rem, 5vw, 2rem);
  font-weight: 700;
  margin-bottom: 3rem;
}

p{
  font-size: clamp(0.93rem, 2vw, 1rem);
}

div .blob-curved{
  position: relative;
  overflow: hidden;
}

.blob-curved{
  height: 100%;
  width: 100%;
  margin-bottom: -10px;
}

.about p{
  margin-bottom: 2rem;
  line-height: 1.4rem;
}

.underline-slide{
  position: relative;
  display: inline-block;
  color: var(--bg-tertiary);
  font-weight: 500;
  margin-bottom: -8px;
  overflow: hidden;
}

.underline-slide::after{
  content: '';
  position: relative;
  display: block;
  left: 0;
  bottom: 0.2rem;
  width: 100%;
  height: 2px;
  background-color: var(--stroke-secondary);
  transform: translate3d(-101%, 0, 0);
  transition:  transform 0.2s linear;
}

.underline-slide:hover::after, .underline-slide:focus::after{
  transform: translate3d(0, 0, 0);
}

.contact-btn{
  margin: 0.5rem 1rem;
}

div:has(.flippable-card){
  overflow:visible !important;
}

.flippable-container{
  display: inline-block;
  width: calc(100% + var(--indent) * 2);
  margin: 50px calc(var(--indent) * -1);
  box-shadow: 0 0 20px rgba(var(--bg-tertiary-rgb), 0.3);
}

.flippable-card{
  --card-height: 150px;

  width: 100%;
  height: var(--card-height);
  position: relative;
  animation: flip_card 15s infinite;
  transform-style: preserve-3d;
}

@keyframes flip_card {
  55%  { transform: translateZ(0) rotateX(0deg);   }
  63%    { transform:  translateZ(calc(var(--card-height) / 2)) rotateX(-90deg); }
  93%  { transform:  translateZ(calc(var(--card-height) / 2)) rotateX(-90deg); }
  100%  { transform: translateZ(0) rotateX(0deg);   }
}

.face {
  width:100%;
  height:100%;
  color: var(--font-primary);
  position:absolute;
  backface-visibility:hidden;
  padding: 10px var(--indent);
}

.face-front{
  background-color:var(--bg-secondary);
  transform: rotateY(0deg) translateZ(calc(var(--card-height) / 2));
}

.face-top{
  background-color:var(--stroke-secondary);
  transform: rotateX(90deg) translateZ(calc(var(--card-height) / 2));
 
}

.alignment-background{
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  z-index: -1;
}

.skills-container{
  margin-top: 80px;
  
  --svg-width: 45px;
}

.skills-container p{
  word-spacing: 5px;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  margin-left: calc(var(--svg-width) / 2.5);
}

.skill{
  margin: 1rem;
  user-select: none;
}

.skill svg{
  width: var(--svg-width);
  height: calc(var(--svg-width) + 3px);
  shape-rendering: geometricPrecision;
  transition: color 0.5s ease-out;
}

.figma path{
  transition: fill 0.5s ease;
  transform: scale(0.99);
}

.hoverTrue:has(.figma) .figma>path:nth-of-type(1){
  fill: #1abcfe;
}

.hoverTrue:has(.figma) .figma>path:nth-of-type(2){
  fill: #0ACF83;
}

.hoverTrue:has(.figma) .figma>path:nth-of-type(3){
  fill: #FF7262;
}

.hoverTrue:has(.figma) .figma>path:nth-of-type(4){
  fill: #F24E1E;
}

.hoverTrue:has(.figma) .figma>path:nth-of-type(5){
  fill: #A258FF;
}

.bg-hover-false svg{
  color: var(--font-tertiary);
}

.skill-text{
  font-size: 0.9rem;
  line-height: 2rem;
  color: var(--font-primary);
  text-transform: capitalize;
}

.break{
  contain: paint;
  position: relative;
  height: 300px;
}

.resume-bg-img{
  position: absolute;
}

.resume-bg-img::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--bg-secondary);
  opacity: 0.7;
}

.resume-break{
  font-size: clamp(1.4rem, 5vw, 1.8rem);
  font-weight: 700;
  color: var(--font-primary);
  word-spacing: 3px;
  z-index: 10;
}

.resume-link{
  color: var(--stroke-secondary);
  font-family: 'Life Savers', serif;
  /* font-size: clamp(2rem, 5vw, 2.3rem); */
  font-size: 2.3rem;
  font-weight: 700;
  text-decoration-skip-ink: none;
  text-decoration-color: var(--btn-theme-hover);
  text-underline-offset: 4px;
}

.resume-link.underline-slide{
  margin-bottom: -1rem;
}

.resume-link.underline-slide::after{
  bottom: 0.65rem;
  height: 4px;
  width: 101%;
}

.scrollable{
  top: 0;
  animation: parallax linear;
  animation-timeline: view();
  animation-range: cover;
}

#astronaut_helmet{
  --parallax-speed: 1;
}

#moon_{
  --parallax-speed: -0.5;
}

#satellite_{
  --parallax-speed: 0.5;
}

.section:has(.projects){
  padding-left: 0;
  padding-right: 0;
}

.projects{
  max-width: 100% !important;
  position: relative;
}

.project .animate-text{
  padding-left: 9px;
}

.section .projects>*:not(.slider-container){
  max-width: 900px;
}

.slider-container{
  position: relative;
}

@media(max-width: 1024px){
  .section:has(.projects){
    padding: calc(var(--indent) * 5) var(--indent);
  }

  .space-background{
    left: -175px;
  }
}

@media(min-width: 1024px){
  .slider-container{
    max-width: 950px;
  }
}

@media(min-width: 1200px){
  .slider-container{
    max-width: 1100px;
  }
}

@media(min-width: 1300px){
  .slider-container{
    max-width: 1200px;
  }
}

.slider-container button{
  background-color: var(--bg-secondary);
  color: var(--hr-break);
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  position: absolute;
  top: 45%;
  right: -1.5rem;
  border: solid 0.1rem rgba(var(--border-font-tertiary-rgb), 0.4);
  border-radius: 9999px;
}

.slider-container button:active{
  transform: scale(1.05);
}

.slider-container .btn-left{
  left: -1.5rem;
}

.slider-container button>svg{
  width: 40px;
  height: 40px;
}

.project-wrapper{
  position: relative;
  overflow: hidden;
  height: 550px;
  border: solid 0.1rem rgba(var(--border-font-tertiary-rgb), 0.3);
  border-radius: 5px;
}

.project-wrapper:hover{
  border: none;
}

/* .project-wrapper>a::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--bg-tertiary);
  opacity: 0.3;
} */

.slick-center .project-wrapper>a::before{
  background: none;
}

.guessdle:hover>a::after{
  background: url('../assets/svg/guessdle-title.svg');
}

.movie-ranker:hover>a::after{
  background: url('../assets/svg/movie-ranker-title.svg');
}

.portfolio:hover>a::after{
  background: url('../assets/svg/portfolio-title.svg');
}

.project-wrapper>a>img{
  width: 100%;
  height: 100%;
}

.prj-cover{
  object-fit: cover;
  opacity: 1;
  transition: all 0.4s ease-out;
}

.project-wrapper:hover .prj-cover{
  transform: scale(1.1);
}

.slick-slide{
  padding: 0.5rem;
  margin: 2rem 0;
}

/* .center .slick-center .project-wrapper{
  transform: scale(1.15);
  z-index: 10;
  opacity: 1;
  pointer-events: all;
} */

/* .center .project-wrapper{
  opacity: 0.7;
  pointer-events: none;
} */

.slick-center:hover .prj-cover{
  filter: blur(2px);
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .project-wrapper{
    height: 500px;
  }
}

@media(max-width: 1024px){
  .project-wrapper>a::before{
    background: none;
  }

  .slick-slide:hover .prj-cover{
    filter: blur(2px);
  }

  /* .center .project-wrapper{
    pointer-events: all;
  } */
}

.section:has(.contact){
  min-height: 0;
}

.section:has(.contact) h2{
  margin: 0;
  margin-bottom: 10px;
  font-size: clamp(1rem, 9vw, 2.5rem);
  line-height: 0.9;
}

.flex-fill:first-child{
  margin-right: 10px;
}

.email-fill{
  margin-top: 15px;
}

.email-fill h5{
  font-weight: 300;
  font-size: clamp(1rem, 2vw, 1.1rem);
  word-spacing: 0.1rem;
  margin-bottom: 50px;
}

.email-fill a, .email-fill h6{
  text-decoration: none;
  font-size: clamp(0.85rem, 2vw, 0.95rem);
  color: var(--font-primary);
  opacity: 0.8;
  margin: 0;
  transition: all 0.3s;
}

.email-fill svg{
  width: 22px;
  height: auto;
}

.email-fill a:hover{
  color: #FFFFFF;
  opacity: 1;
}

.contact form label:first-child{
  margin-top: 0;
}

.contact form label{
  margin-top: 2rem;
  margin-bottom: 5px;
  font-size: 0.8rem;
  color: var(--font-tertiary);
  opacity: 0.8;
  text-align: left !important;
}

.contact form input, textarea{
  width: 100%;
  color: var(--font-primary);
  background-color: var(--bg-secondary);
  border: solid 0.1rem rgba(var(--border-font-tertiary-rgb), 0.3);
  border-radius: 0.15rem;
  padding: 0.6rem;
  font-size: 0.9rem;
  resize: none;
}

.contact form span{
  display: none;
  margin: 5px 0;
  color: var(--outline-important);
  font-size: 0.8rem;
  word-spacing: 2px;
}

.contact form input:invalid[focused="true"], .contact form textarea:invalid[focused="true"]{
  outline: solid 0.11rem var(--outline-important);
  border-color: red;
  box-shadow: inset 0 0 20px rgba(255, 0, 0, 0.1), 0 0 20px rgba(255, 0, 0, 0.3);
}

.contact form input:invalid[focused="true"] + .inputSpan, .contact form textarea:invalid[focused="true"] + .txtSpan{
  display: flex;
}

.contact form span svg{
  margin-right: 5px;
}

.contact form input:focus, .contact form textarea:focus{
  outline: solid 0.11rem var(--outline-focus) !important;
  border-color: var(--outline-focus) !important;
  box-shadow: inset 0 0 20px rgba(0, 102, 255, 0.2), 0 0 20px rgba(0, 102, 255, 0.3) !important;
}

.send-btn{
  background-color: var(--font-secondary);
  color: var(--bg-secondary);
  border: solid 0.1rem var(--font-secondary);
  border-radius: 0.1rem;
  margin-top: 2rem;
  font-weight: 500;
  padding: 0.5rem 1.5rem;
  position: relative;
  z-index: 1;
  overflow: hidden
}

.send-btn::after{
  content: "";
  background-color: var(--bg-secondary);
  position: absolute;
  z-index: -1;
  display: block;
  transition: all 0.5s ease;
  left: -20%;
  right: -20%;
  bottom: 0;
  top: 0;
  transform: skewX(-45deg) scale(0, 1);
}

.send-btn:hover{
  color: var(--font-primary);
  letter-spacing: 1px;
  transition: all 0.5s;
}

.send-btn:hover::after{
  transition: all 0.5s ease-out;
  transform: skewX(-45deg) scale(1, 1);
}

.send-btn:active{
  transform-origin: 100% 50%;
  transform: scale(1.05);
}

.contact-background{
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

footer{
  margin: auto;
  width: 100%;
}

.section:has(.contact) ~ footer{
  background-color: var(--bg-main);
}

hr {
  margin: 0;
  border-top: 1px solid var(--hr-break);
}

.socials{
  margin-top: 2rem;
}

.socials > *{
  position: relative;
  margin: 0 0.4rem;
  cursor: pointer;
}

.socials>a svg{
  color: var(--hr-break);
  height: 30px;
  width: auto;
  shape-rendering: geometricPrecision;
  transition: transform 0.3s;
}

.socials>a:hover svg{
  transform: scale(1.2);
  overflow: hidden;
}

.footer-credentials{
  margin-top: 1.2rem;
  font-weight: 500;
  color: var(--font-primary);
  opacity: 0.2;
  font-size: 0.75rem;
  margin-bottom: 15px;
  word-spacing: 2.5px;
}

.footer-credentials:hover{
  color: var(--font-tertiary);
  opacity: 1;
}

/* body:has(.loading-screen){
  overflow: hidden;
} */

.loading-screen{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
}

/* .slide-in{
  transform-origin: bottom;
} */

.slide-out{
  transform-origin: top;
}

@media(max-width: 600px){
  .theme-change-btn{
    border: solid 0.15rem var(--bg-tertiary);
  }

  .star{
    font-size: 1.2rem;
    transform: scale(0.8);
    background-color: var(--bg-main);
    color: #FFFFFF;
  }

  .star:before{
    transform: scale(0.8);
    -webkit-transform: rotate(15deg);
  }

  .section{
    padding: calc(var(--indent) * 3) calc(var(--indent) / 1.6) !important;
  }
  
  .section .intro {
    margin-top: 1rem;
  }

  .space-background{
    left: -100px;
  }

  .contact-btn{
    margin: 1rem 5rem 0 0;
  }

  .flippable-card{
    --card-height: 115px;
  }

  .face{
    font-size: 0.93rem;
  }

  .intro h1{
    margin: 0;
  }

  .intro h3{
    margin-left: 4px;
  }

  .skills-container{
    --svg-width: 37px;
  }

  .skills-container p{
    margin-left: 0;
    text-align: center;
  }

  .skills{
    justify-content: center;
  }

  .skill-text{
    font-size: 0.8rem;
  }

  .slick-slide{
    margin-top: 2rem;
  }

  .send-btn{
    width: 100%;
  }

  .resume-link{
    font-size: 2rem;
    text-underline-offset: 3px;
  }

  #message-textbox{
    height: 5rem;
  }

  #cursor, #cursorCircle{
    display: none !important;
  }

  .contact-background{
    left: -210px;
    top: -30px;
  }
}

@media(min-width: 600px) and (max-width: 900px){
  html:has(.responsive-nav){
    margin-right: 10px;
  }

  header:has(.responsive-nav){
    margin-right: calc(var(--indent) + 10px);
  }

  .section:has(.contact){
    align-items: center !important;
  }

  .flex-fill{
    margin-right: 0 !important;
    width: 100%;
  }

  .contact-background{
    top: 55px;
  }
}

@media only screen and (max-width: 900px){
  .section>div{
    max-width: none;
  }

  .nav-btn{
    display: block;
    position: absolute;
    border: 2px solid var(--bg-tertiary);
    border-radius: 10px;
  }

  .hidden {
    display: none;
  }

  .hamburger {
    margin-right: 10px;
    width: 30px;
    height: 30px;
    position: relative;
    cursor: pointer;
  }

  .hamburger .bar {
    padding: 0;
    width: 30px;
    height: 3px;
    background-color: var(--bg-tertiary);
    display: block;
    border-radius: 4px;
    transition: all 0.7s ease-in-out;
    position: absolute; 
  }

  .bar1 {
    top: 2px;
  }

  .bar2,
  .bar3 {
      top: 12.5px;
  }

  .bar3 {
      right: 0;
  }

  .bar4 {
    bottom: 2px;

  }

  nav.responsive-nav + .nav-btn + label > .hamburger > .bar1{
    transform: rotate(45deg);
    transform-origin: 5%;
    width: 37px
  }

  nav.responsive-nav + .nav-btn + label > .hamburger > .bar2 {
    transform: translateX(-40px);
    background-color: transparent;
  }

  nav.responsive-nav + .nav-btn + label > .hamburger > .bar3 {
    transform: translateX(40px);
    background-color: transparent;
  }

  nav.responsive-nav + .nav-btn + label > .hamburger > .bar4 {
    transform-origin: 5%;
    transform: rotate(-45deg);
    width: 37px;
  }

  .header-component a{
    font-size: 1rem;
  }

  nav{
    position: fixed;
    top: -100vh;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-secondary);
    transition: 1s;
    transform: translateY(-100vh);
  }

  html:has(.responsive-nav){
    overflow: hidden;
  }

  .responsive-nav{
    transform: translateY(100vh);
  }

  .scrolled{
    transition: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  }
}

@media(max-width: 900px){
  .section:has(.contact){
    flex-flow: column !important;
  }

  .section .projects>*:not(.slider-container){
    max-width: 600px;
  }

  .email-fill{
    margin-bottom: 50px;
  }

 .break{
    height: 200px;
  }

  .resume-bg-img{
    transform: scale(0.8);
  }
}

@media(min-width: 900px) and (max-width:1100px){
  .contact form{
    min-width: 375px;
  } 
}

@media(min-width: 900px){
  .section:has(.contact){
    min-height: 1080px;
    padding-top: calc(var(--indent) *8);
  }
}

@media (max-width: 1200px) {
  header{
    margin: calc(var(--indent) / 1.5) calc(var(--indent));
  }

  .section{
    padding: calc(var(--indent) * 5) var(--indent);
  }

  .section:has(.intro){
    padding-top: calc(var(--indent) * 9);
  }
}

@media (min-width: 1200px) {
  .section:has(.contact){
    padding-top: calc(var(--indent) *4.5);
  }
}